.bar {
    display: flex;
    justify-content: space-between;
    background-color: aliceblue;
    padding: 4px;
    border-bottom: 1px solid grey;
}
.navigation {
    font-size: 14px;
    padding: 4px;
    cursor: pointer;
    text-transform: uppercase;
}
.userinfos {
    display: flex;
}
.logoDiv {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 10px;
}
.logo {
    height: 32px;
    image-rendering: auto;
}
.image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 6px;
}
.name {
    background-color: #673AB7;
    color: white;
}
.logout{
    margin: 10px;
}
.id{
    font-size: 14px;
    padding: 4px;
}
.idtoggle{
    font-size: 14px;
    padding: 4px;
    cursor: pointer;
}