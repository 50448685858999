.ArcDemoRoot {
  font-family: sans-serif;
  height: 100%;
  overflow: auto;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  /* align-items: flex-start; */
}
.ArcDemoRootChild {
  width: fit-content;
}
.CompRow {
  display: flex;
}
.CompCol {
  display: flex;
  flex-direction: column;
}