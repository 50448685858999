.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: aqua; */
}

.logo {
    margin-top: 100px;
    margin-bottom: 60px;
    height: 50px;
}

.terms {
    margin-top: 18px;
    margin-bottom: 60px;
    font-style: 18px;
    color: #aaaaaa;
    font-style: italic;
}